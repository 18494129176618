<template>
  <tabs>
    <tab
      ref="tabItem"
      v-for="(item, key) in totalItems"
      :key="item.key"
      :name="(key + 1).toString()"
      :selected="item.selected"
    >
      <vs-button
        class="includeIconOnly erase-btn-rcs"
        color="warning"
        type="filled"
        icon-pack="feather"
        icon="icon-trash"
        v-if="totalItems.length > 1"
        :disabled="readOnly"
        @click="remove(key)"
      ></vs-button>
      <RCSCard
        :card="cardArr[key]"
        @update="onChildUpdate"
        ref="sliderItem"
        :indexItem="key"
        :keyItem="item.key"
        :read-only="readOnly"
      ></RCSCard>
    </tab>
  </tabs>
</template>
<script>
import { v4 as uuid } from 'uuid'

const emptyCardItem = {
  index: 0,
  header: {
    url: ''
  },
  options: [{ text: '' }, { text: '' }, { text: '' }, { text: '' }],
  subtitle: '',
  title: ''
}
export default {
  props: {
    readOnly: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      selectedValue: 0,
      totalItems: [
        {
          key: uuid(),
          selected: true
        }
      ],
      cardArr: [emptyCardItem]
    }
  },
  components: {
    tab: () => import('./Tab'),
    tabs: () => import('./Tabs'),
    RCSCard: () => import('./Card')
  },
  methods: {
    getSelected(key) {
      return key == this.selectedValue
    },
    addItem() {
      this.totalItems.push({
        key: uuid(),
        selected: false
      })
      const newCardItem = {
        index: 0,
        header: {
          url: ''
        },
        options: [{ text: '' }, { text: '' }, { text: '' }, { text: '' }],
        subtitle: '',
        title: ''
      }
      const index = this.cardArr.length
      newCardItem.index = index
      this.cardArr.push(newCardItem)
    },
    load(cards) {
      this.totalItems = []
      this.cardArr = cards.map((element, index) => {
        element.index = index
        return element
      })
      this.cardArr.forEach((el, index) => {
        if (index > 0)
          this.totalItems.push({
            key: uuid(),
            selected: false
          })
        else {
          this.totalItems.push({
            key: uuid(),
            selected: true
          })
        }
      })
    },
    erase() {
      this.load([
        {
          index: 0,
          header: {
            url: ''
          },
          options: [{ text: '' }, { text: '' }, { text: '' }, { text: '' }],
          subtitle: '',
          title: ''
        }
      ])
    },
    remove(key) {
      this.totalItems.splice(key, 1)
      this.cardArr.splice(key, 1)
      this.cardArr.forEach((element, index) => {
        this.cardArr[index].index = index
      })
      let keyEmmit
      if (key < this.totalItems.length) {
        keyEmmit = key + 1
      } else {
        keyEmmit = key - 1
      }
      this.$emit('activeTab', keyEmmit + 1)
      this.$emit(
        'sliderItemsUpdate',
        this.cardArr.map(function(el) {
          delete el.index
          return el
        })
      )
    },
    onChildUpdate(newValue) {
      const itemIndex = newValue.index
      this.cardArr[itemIndex] = newValue.card
      this.cardArr[itemIndex].index = itemIndex
      this.$emit(
        'sliderItemsUpdate',
        this.cardArr.map(function(el) {
          delete el.index
          return el
        })
      )
    }
  }
}
</script>

<style lang="scss">
.erase-btn-rcs {
  margin-right: 0;
  margin-left: auto;
  position: absolute;
  right: 0;
  top: 0;
}
</style>
